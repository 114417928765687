import styled, { css } from 'styled-components';

import { ErrorNotificationWrapper } from 'src/components/ErrorNotification/error-notification-styled';
import { HHero, H1, Text1Bold, H4 } from 'src/components/Text';
import { smMedia, mdMedia, themeColor, themeImages } from 'src/theme/utils';

export const StepDietPlannersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  margin-bottom: 24px;
  ${HHero} {
    display: none;
  }
  ${H1} {
    display: flex;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  ${Text1Bold} {
    text-align: left;
  }
  ${ErrorNotificationWrapper} {
    margin: 16px 0;
  }
  ${mdMedia(css`
    ${HHero} {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 8px;
    }
    ${H1} {
      display: none;
    }
    ${Text1Bold} {
      text-align: center;
    }
  `)}
`;

export const StepDietPlannersChoices = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 22px;
  ${smMedia(css`
    flex-direction: row;
  `)}

  ${mdMedia(css`
    flex-direction: row;
  `)}
`;

export const StepDietPlannersChoicesItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
  width: 100%;
  height: 128px;
  margin-bottom: 16px;
  &:nth-child(1) {
    background: ${themeColor('orange0')};
    button {
      background: ${themeColor('orange10')};
    }
  }
  &:nth-child(2) {
    background: ${themeColor('blue0')};
    button {
      background: ${themeColor('blue10')};
    }
  }
  &:nth-child(3) {
    background: ${themeColor('secondary0')};
    button {
      background: ${themeColor('secondary10')};
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  ${smMedia(css`
    width: 220px;
    height: 382px;
    flex-direction: column;
    border-radius: 9px;
    margin-bottom: 0;
  `)}

  ${mdMedia(css`
    width: 328px;
    height: 382px;
    flex-direction: column;
    border-radius: 9px;
    margin-bottom: 0;
  `)}
`;

export const ChoiseImg = styled.div`
  display: flex;
  max-height: 136px;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 36px;
  img {
    height: calc(100% + 8px);
    margin-top: -8px;
    margin-left: auto;
    margin-right: auto;
  }
  ${smMedia(css`
    position: relative;
    right: auto;
    max-height: 327px;
    img {
      height: calc(100% + 16px);
      margin-top: -16px;
    }
  `)}

  ${mdMedia(css`
    position: relative;
    right: auto;
    max-height: 327px;
    img {
      height: calc(100% + 16px);
      margin-top: -16px;
    }
  `)}
`;
export const ChoiseBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  border: none;
  outline: none;
  height: fit-content;
  border-radius: 9px;
  font-family: 'IBM Plex Sans', sans-serif;
  padding: 16px;
  width: 100%;
  height: 100%;
  &[disabled] {
    cursor: not-allowed;
  }
  &:hover {
    opacity: 0.5;
  }
  ${H4} {
    margin-top: auto;
    margin-bottom: auto;
    &:last-child {
      display: none;
    }
  }
  ${mdMedia(css`
    height: 56px;
    border-radius: 0;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    ${H4} {
      &:last-child {
        display: block;
      }
    }
  `)}
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin: 1.5rem auto 0;
  max-width: 556px;
  ${smMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 2.5rem 0 0;
  `)}
`;

export const PrivacyCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;

export const EmailConfirmWrapper = styled.div`
  display: flex;
  margin: 1rem auto 0;
  max-width: 556px;
  ${smMedia(css`
    margin-left: 0;
  `)}
  ${mdMedia(css`
    margin: 1rem 0 0;
  `)}
`;

export const EmailConfirmCheckbox = styled.div`
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-right: 0.5rem;
  background: url(/icons/cmn_check_default.svg) no-repeat center;
  background-size: contain;
  cursor: pointer;
  &.checked {
    background: url(${themeImages('iconCheck')}) no-repeat center;
  }
`;
